/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import { ContextualAnalyticsData, SCREEN, FireScreenAnalytics } from '@atlassian/analytics-bridge';
import { normalizeSourceName } from '../../../utils/analytics';
import { mainContentStyles } from '../components/layout';
import MetaTags from '../components/meta-tags';

// FIXME: Add type safety
const PageTemplate = ({ children, pageContext, uri }: any) => {
	const parts = uri.split('/');
	let sourceName = normalizeSourceName(parts[parts.length - 1]);

	if (pageContext?.frontmatter) {
		sourceName = pageContext.frontmatter.title;
	}

	return (
		<ContextualAnalyticsData
			sourceType={SCREEN}
			sourceName="content"
			attributes={{ name: sourceName, context: 'mdx' }}
		>
			{pageContext?.frontmatter && (
				<MetaTags
					title={pageContext.frontmatter.title}
					description={pageContext.frontmatter.description}
				/>
			)}
			<FireScreenAnalytics key={sourceName} />
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */}
			<div css={mainContentStyles}>{children}</div>
		</ContextualAnalyticsData>
	);
};

export default PageTemplate;
